.react-datepicker {
  font-family: inherit;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  //transform-origin: top center;
  outline: 0;

  &-popper {
    opacity: 1;
    //transform: none;
    //transition: opacity 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 213ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 10;
  }

  &__header {
    background-color: transparent;
    border-bottom: none;
  }
  &__day {

  &:hover {
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.04);
  }
  &--selected {
    border-radius: 1rem;
    background-color: #e91e63;

    &:hover {
      border-radius: 1rem;
      background-color: #e91e63;
    }
  }
  }

  &__time-list-item {

  &--selected {

  }
  }
}
